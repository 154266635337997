import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { logOut, setCredentials } from '../../features/auth/authSlice'
import { Modal } from 'antd'
const version = 2.0
const devMode = false
// baseUrl: 'https://middleware-dev.spacecargo.ge/', test
// baseUrl: 'https://middleware.spacecargo.ge/', prod
const _baseUrl = devMode
	? 'https://middleware-dev.spacecargo.ge/'
	: 'https://middleware.spacecargo.ge/'

const baseQuery = fetchBaseQuery({
	baseUrl: _baseUrl,
	prepareHeaders: (headers, { getState }) => {
		const token = window.localStorage.getItem('token')
		if (token) {
			headers.set('authorization', `Bearer ${token}`)
		}
		return headers
	}
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions)
	if (result?.error?.status === 401) {
		Modal.info({
			title: 'თქვენ სესიას გაუვიდა ვადა',
			onOk: () => {
				api.dispatch(logOut())
				console.log('Redirecting to login...')
				window.location = '/login'
			}
		})
	}
	return result
}

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({})
})
