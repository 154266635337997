import { createSlice } from '@reduxjs/toolkit'

const delegationSlice = createSlice({
	name: 'delegation',
	initialState: {
		delegationData: null
	},
	reducers: {
		setDelegationData: (state, action) => {
			const { delegations } = action.payload
			state.delegationData = delegations
		}
	}
})

export const { setDelegationData } = delegationSlice.actions

export default delegationSlice.reducer

export const selectDelegationData = state => state.delegationData.delegation
