import { createSlice } from '@reduxjs/toolkit'

const categoriesSlice = createSlice({
	name: 'delegationCategories',
	initialState: {
		categories: null
	},
	reducers: {
		setDelegationCategoriesData: (state, action) => {
			const { categoriesData } = action.payload
			state.categories = categoriesData
		}
	}
})

export const { setDelegationCategoriesData } = categoriesSlice.actions

export default categoriesSlice.reducer

export const selectDelegationCategories = state =>
	state.delegationCategories.categories
