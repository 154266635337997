import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

// for browser use xhr backend to load translations and browser lng detector

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		supportedLngs: ['ge', 'en'],
		fallbackLng: 'ge',
		detection: {
			order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
			caches: ['cookie']
		},
		backend: {
			loadPath: '/assets/locales/{{lng}}/translation.json'
		},
		react: { useSuspense: false }
	})
	.then()

export default i18n
