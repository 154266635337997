import { createSlice } from '@reduxjs/toolkit'

const companyDataSlice = createSlice({
	name: 'companyData',
	initialState: {
		companies: null,
		countries: null,
		banks: null,
		addingState: false
	},
	reducers: {
		setCompanyData: (state, action) => {
			const { companiesData } = action.payload
			state.companies = companiesData
		},
		setBanks: (state, action) => {
			const { receivedBanks } = action.payload
			state.banks = receivedBanks
		},
		setCountries: (state, action) => {
			const { receivedCountries } = action.payload
			state.countries = receivedCountries
		}
	}
})

export const { setCompanyData, setBanks, setCountries } =
	companyDataSlice.actions

export default companyDataSlice.reducer

export const selectCompanies = state => state.companyData.companies
export const selectCountries = state => state.companyData.countries
export const selectBanks = state => {
	return state.companyData.banks
}
export const selectAddingState = state => {
	return state.companyData.addingState
}
