import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import './css/font-awesome.css'
import './i18n'

import { store } from './app/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import '../src/Components/shared/global.css'
import '../src/css/formElements.css'
import '../src/css/style.css'
import '../src/css/gaps.css'
import '../src/css/mobile.css'

// const store = setupStore();
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
)
