import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { iconMap } from '../shared/Common'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
	selectCurrentDeviceViewPort,
	setCollapsed
} from '../../features/app/appSlice'
import { useAuthUserMutation } from '../../features/auth/authApiSlice'
import {
	logOut,
	selectCurrentUser,
	setAuthUser
} from '../../features/auth/authSlice'
import Cookies from 'js-cookie'
import i18next from 'i18next'

import SidebarContainer from './SidebarContainer'

import Sider from 'antd/es/layout/Sider'

import '../../css/style.css'
import '../shared/global.css'
import s from './sidebar.module.css'
import { AiOutlineLogout, AiOutlineSetting } from 'react-icons/ai'
import { HiOutlineLanguage } from 'react-icons/hi2'
import { VscColorMode } from 'react-icons/vsc'

const SidebarContent = props => {
	const userPermissions = props.userPermissions
	const globalWarningMessage = props.globalWarningMessage
	const { t } = useTranslation()
	console.log('userPermissions', userPermissions)
	const navPages = userPermissions
		?.map(item => {
			const Icon = iconMap[item.iconUrl]

			let menuItem = null // Initialize as null
			if (item.visible === 'Y') {
				menuItem = {
					label: t(item.pageDictionaryKey),
					key: item.pageUrl,
					icon: Icon ? <Icon /> : null,
					count: item.count
				}
			}

			if (item.children && item.children.length > 0) {
				let menuItemChild = item.children
					.map(child => {
						const Icon = iconMap[child.iconUrl]
						if (child.visible === 'Y') {
							return {
								label: t(child.pageDictionaryKey),
								key: child.pageUrl,
								icon: Icon ? <Icon /> : null,
								count: child.count
							}
						}
						return null // Explicitly return null if not visible
					})
					.filter(Boolean) // Remove null values
				menuItem.children = menuItemChild
			}
			return menuItem
		})
		.filter(Boolean) // Remove null values from the outer array

	const settingsMenuItems = [
		{
			label: t('text.settings'),
			key: 'settings',
			icon: <AiOutlineSetting />,
			children: [
				{
					icon: <HiOutlineLanguage />,
					label: t('text.language'),
					key: 'changeLang'
				},
				{
					icon: <VscColorMode />,
					label: t('text.theme'),
					key: 'changeTheme'
				}
			]
		}
	]

	const [authUser] = useAuthUserMutation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleLogout = async e => {
		dispatch(logOut())
		navigate('/login')
	}

	const getAuthUserData = async () => {
		try {
			const authUserData = await authUser().unwrap()
			dispatch(setAuthUser({ user: authUserData }))
		} catch (err) {
			console.log('error', err)
			// handleLogout()
		}
	}

	const deviceViewPort = useSelector(selectCurrentDeviceViewPort)

	const LoginedUser = () => {
		const user = useSelector(selectCurrentUser)
		return user !== null
	}

	const setCollapsedSidebar = async () => {
		dispatch(setCollapsed())
	}

	const langChecked = () => {
		const currentLanguage = Cookies.get('i18next') || 'ge'
		return currentLanguage === 'ge'
	}

	const changeLanguage = () => {
		const currentLanguage = Cookies.get('i18next') || 'en'
		let lang = currentLanguage === 'en' ? 'ge' : 'en'
		i18next.changeLanguage(lang).then()
	}

	useEffect(() => {
		const token = window.localStorage.getItem('token')
		if (token && LoginedUser !== null) {
			getAuthUserData().then()
		}
	}, [])
	return (
		<>
			{!props.authUserData ? (
				<Sider
					width={240}
					collapsed={props.collapsed}
					theme={props.sidebarTheme}
					className={s.sidebar}
				/>
			) : (
				<SidebarContainer
					navPages={navPages}
					settingsMenuItems={settingsMenuItems}
					sidebarTheme={props.sidebarTheme}
					collapsed={props.collapsed}
					setCollapsedSidebar={setCollapsedSidebar}
					setDarkSidebarTheme={props.setDarkSidebarTheme}
					langChecked={langChecked}
					changeLanguage={changeLanguage}
					deviceViewPort={deviceViewPort}
					authUserData={props.authUserData}
					handleLogout={handleLogout}
					globalWarningMessage={globalWarningMessage}
				/>
			)}
		</>
	)
}

let mapStateToProps = state => ({
	authUserData: state.auth.user
})

export default connect(mapStateToProps, {})(SidebarContent)
