import { createSlice } from '@reduxjs/toolkit'

const departmentsSlice = createSlice({
	name: 'departments',
	initialState: {
		departments: null
	},
	reducers: {
		setDepartmentsData: (state, action) => {
			const { departmentsData } = action.payload
			state.departments = departmentsData
		}
	}
})

export const { setDepartmentsData } = departmentsSlice.actions

export default departmentsSlice.reducer

export const selectDepartments = state => state.departments.departments
