import { apiSlice } from '../../app/api/apiSlice'

export const authApiSlice = apiSlice.injectEndpoints({
	tagTypes: ['Auth'],
	endpoints: build => ({
		login: build.mutation({
			query: body => ({
				url: '/admin/login',
				method: 'POST',
				body: { ...body }
			}),
			invalidatesTags: ['Auth']
		}),
		authUser: build.mutation({
			query: body => ({
				url: '/admin/get_user_info',
				method: 'POST',
				body: { ...body }
			}),
			invalidatesTags: ['Auth']
		}),
		getUserPermissions: build.query({
			query: args => {
				return {
					url: `/admin/permissions/get_user_permissions`
				}
			},
			providesTags: ['Auth']
		})
	})
})

export const {
	useLoginMutation,
	useAuthUserMutation,
	useGetUserPermissionsQuery
} = authApiSlice
