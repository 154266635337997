import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: null,
		token: null,
		userPermissions: null,
		globalWarningMessage: null
	},
	reducers: {
		setCredentials: (state, action) => {
			const { user, accessToken } = action.payload
			state.user = user
			state.token = accessToken
			window.localStorage.setItem('token', accessToken)
			document.cookie = `token=${accessToken}; Secure; HttpOnly`
		},
		setAuthUser: (state, action) => {
			const { user } = action.payload
			state.user = user.user
		},
		setUserPermissions: (state, action) => {
			const { userPermissions } = action.payload
			state.userPermissions = userPermissions
		},
		setUserGlobalWarningMessage: (state, action) => {
			const { globalWarningMessage } = action.payload
			state.globalWarningMessage = globalWarningMessage
		},
		logOut: (state, action) => {
			state.user = null
			state.token = null
			window.localStorage.removeItem('token')
			window.localStorage.removeItem('user')
		}
	}
})

export const {
	setCredentials,
	setAuthUser,
	setUserPermissions,
	setUserGlobalWarningMessage,
	logOut
} = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = state => state.auth.user
export const selectCurrentToken = state => state.auth.token
export const selectUsersPermissions = state => state.auth.userPermissions
export const selectUsersGlobalWarningMessage = state =>
	state.auth.globalWarningMessage
