import { createSlice } from '@reduxjs/toolkit'

const invoicesSlice = createSlice({
	name: 'invoices',
	initialState: {
		purchaseInvoice: null,
		salesInvoice: null,
		countries: null,
		companies: null,
		currencies: null,
		currencyTypes: null
	},
	reducers: {
		setSalesInvoiceData: (state, action) => {
			const { salesInvoice } = action.payload
			state.salesInvoice = salesInvoice
		},
		setSalesInvoiceReceiverData: (state, action) => {
			const { receiverInfo } = action.payload
			state.salesInvoice.receiverInfo = receiverInfo
		},
		setSalesInvoiceDueData: (state, action) => {
			const { dueDate } = action.payload
			state.salesInvoice.dueDate = dueDate
		},
		setPurchaseInvoiceDueData: (state, action) => {
			const { dueDate } = action.payload
			state.purchaseInvoice.dueDate = dueDate
		},
		setSalesInvoiceIssueData: (state, action) => {
			const { issueDate } = action.payload
			state.salesInvoice.issueDate = issueDate
		},
		setPurchaseInvoiceIssueData: (state, action) => {
			const { issueDate } = action.payload
			state.purchaseInvoice.issueDate = issueDate
		},
		setSalesInvoiceNoteData: (state, action) => {
			const { invoiceNote } = action.payload
			state.salesInvoice.invoiceNote = invoiceNote
		},
		setPurchaseInvoiceNoteData: (state, action) => {
			const { invoiceNote } = action.payload
			state.purchaseInvoice.invoiceNote = invoiceNote
		},
		setSalesInvoiceServices: (state, action) => {
			const { services } = action.payload
			state.salesInvoice.invoiceServices = services
		},
		setPurchaseInvoiceServices: (state, action) => {
			const { services } = action.payload
			state.purchaseInvoice.invoiceServices = services
		},
		setPurchaseInvoiceData: (state, action) => {
			const { purchaseInvoice } = action.payload
			state.purchaseInvoice = purchaseInvoice
		},
		setCountries: (state, action) => {
			const { receivedCountries } = action.payload
			state.countries = receivedCountries
		},
		setCompanies: (state, action) => {
			const { companiesData } = action.payload
			state.companies = companiesData
		},
		setCurrencies: (state, action) => {
			const { receivedCurrencies } = action.payload
			state.currencies = receivedCurrencies
		},
		setCurrencyTypes: (state, action) => {
			const { receivedCurrencyTypes } = action.payload
			state.currencyTypes = receivedCurrencyTypes
		},
		setPurchaseInvoiceClientData: (state, action) => {
			const { senderInfo } = action.payload
			state.purchaseInvoice.senderInfo = senderInfo
		},
		setPurchaseInvoiceCcyStateData: (state, action) => {
			const { ccyState } = action.payload
			state.purchaseInvoice.baseCcy = ccyState.baseCcy
			state.purchaseInvoice.ccy = ccyState.ccy
			state.purchaseInvoice.ccyRate = ccyState.ccyRate
			state.purchaseInvoice.ccyRateNote = ccyState.ccyRateNote
			state.purchaseInvoice.ccyType = ccyState.ccyType
		},
		setSalesInvoiceCcyStateData: (state, action) => {
			const { ccyState } = action.payload
			state.salesInvoice.baseCcy = ccyState.baseCcy
			state.salesInvoice.ccy = ccyState.ccy
			state.salesInvoice.ccyRate = ccyState.ccyRate
			state.salesInvoice.ccyRateNote = ccyState.ccyRateNote
			state.salesInvoice.ccyType = ccyState.ccyType
		},
		setPurchaseInvoiceCompanyId: (state, action) => {
			const { companyId } = action.payload
			state.purchaseInvoice.companyId = companyId
		},
		setSalesInvoiceCompanyId: (state, action) => {
			const { companyId } = action.payload
			state.salesInvoice.companyId = companyId
		}
	}
})

export const {
	setPurchaseInvoiceData,
	setPurchaseInvoiceClientData,
	setPurchaseInvoiceDueData,
	setPurchaseInvoiceIssueData,
	setPurchaseInvoiceNoteData,
	setPurchaseInvoiceServices,
	setPurchaseInvoiceCcyStateData,
	setSalesInvoiceCcyStateData,
	setSalesInvoiceData,
	setSalesInvoiceReceiverData,
	setSalesInvoiceDueData,
	setSalesInvoiceIssueData,
	setSalesInvoiceNoteData,
	setSalesInvoiceServices,
	setCountries,
	setCompanies,
	setCurrencies,
	setCurrencyTypes,
	setPurchaseInvoiceCompanyId,
	setSalesInvoiceCompanyId
} = invoicesSlice.actions

export default invoicesSlice.reducer

export const selectPurchaseInvoiceData = state => state.invoices.purchaseInvoice
export const selectSalesInvoiceData = state => state.invoices.salesInvoice
export const selectSalesInvoiceSenderData = state =>
	state.invoices.salesInvoice.invoiceSenderInfo
export const selectSalesInvoiceReceiverData = state =>
	state.invoices.salesInvoice.invoiceReceiverInfo
export const selectSalesInvoiceDetailsData = state =>
	state.invoices.salesInvoice.invoiceDetails
export const selectSalesInvoiceServicesData = state =>
	state.invoices.salesInvoice.invoiceServices
export const selectCountries = state => state.invoices.countries
export const selectCompanies = state => state.invoices.companies
export const selectCurrencies = state => state.invoices.currencies
export const selectCurrencyTypes = state => state.invoices.currencyTypes
