import { Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	logOut,
	selectCurrentUser,
	setAuthUser,
	setUserGlobalWarningMessage,
	setUserPermissions
} from './authSlice'
import { useAuthUserMutation, useGetUserPermissionsQuery } from './authApiSlice'
import { useEffect, useState } from 'react'

const RequireAuth = () => {
	const [authUser] = useAuthUserMutation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [permissionsLoaded, setPermissionsLoaded] = useState(false) // Add a state variable to track if permissions are loaded

	const handleLogout = e => {
		dispatch(logOut())
		navigate('/login')
	}

	const { data: userPermissions } = useGetUserPermissionsQuery()

	const getAuthUserData = () => {
		try {
			const authUserData = authUser().unwrap()
			if (authUserData.type === 'success') {
				dispatch(setAuthUser({ user: authUserData }))
			}
		} catch (err) {
			handleLogout()
		}
	}

	const setUserPermissionsState = async () => {
		dispatch(
			setUserPermissions({ userPermissions: userPermissions?.permissions })
		)
		dispatch(
			setUserGlobalWarningMessage({
				globalWarningMessage: userPermissions?.message[0]?.detail
			})
		)
	}

	const LoginedUser = () => {
		const user = useSelector(selectCurrentUser)
		return user !== null
	}

	useEffect(() => {
		const token = window.localStorage.getItem('token')
		if (token && LoginedUser !== null) {
			getAuthUserData()
		} else {
			handleLogout()
		}
	}, [])

	useEffect(() => {
		if (userPermissions && !permissionsLoaded) {
			// Check if permissions are loaded and not already set in state
			setUserPermissionsState().then()
			setPermissionsLoaded(true)
		}
	}, [userPermissions])

	return <Outlet />
}

export default RequireAuth
