import React from 'react'
import { Spin } from 'antd'

let Profile = props => {
	if (!props.userProfile) {
		return (
			<div className={'spinLocation'}>
				<Spin size='large' spinning={true} className={'spinnn'} />
			</div>
		)
	}

	return (
		<div className={'twoColumnContent'}>
			{Object.keys(props.authUserData).map(function (key) {
				return (
					<div key={key}>
						{key}, - {props.authUserData[key]}
					</div>
				)
			})}
		</div>
	)
}

export default Profile
