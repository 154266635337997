import { createSlice } from '@reduxjs/toolkit'

const processingParcelsSlice = createSlice({
	name: 'processingParcels',
	initialState: {
		processingParcelGroups: null,
		providerParcels: null
	},
	reducers: {
		setProcessingParcelsGroupsList: (state, action) => {
			const { processingParcelsGroupsListTranslated } = action.payload
			state.processingParcelGroups = processingParcelsGroupsListTranslated
		},
		setProviderParcels: (state, action) => {
			const { providerParcels } = action.payload
			state.providerParcels = providerParcels
		}
	}
})

export const { setProcessingParcelsGroupsList, setProviderParcels } =
	processingParcelsSlice.actions

export default processingParcelsSlice.reducer

export const selectProcessingParcelsGroups = state =>
	state.processingParcels.processingParcelGroups
