import { createSlice } from '@reduxjs/toolkit'

const fileCategoriesSlice = createSlice({
	name: 'delegationFileCategories',
	initialState: {
		fileCategories: null
	},
	reducers: {
		setDelegationFileCategoriesData: (state, action) => {
			const { fileCategoriesData } = action.payload
			state.delegationFileCategories = fileCategoriesData
		}
	}
})

export const { setDelegationFileCategoriesData } = fileCategoriesSlice.actions

export default fileCategoriesSlice.reducer

export const selectDelegationFileCategories = state =>
	state.delegationFileCategories.fileCategories
