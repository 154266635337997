import { createSlice } from '@reduxjs/toolkit'

const projectsSlice = createSlice({
	name: 'projects',
	initialState: {
		activeEmployees: null,
		projectsArray: null,
		pageSize: 10,
		totalItemsCount: 10,
		isFetching: false,
		currentPage: 1
	},
	reducers: {
		setActiveEmployees: (state, action) => {
			const { activeEmployees } = action.payload
			state.activeEmployees = activeEmployees
		},
		setProjectsReducer: (state, action) => {
			const { projectsList } = action.payload
			state.projectsArray = projectsList
		}
	}
})

export const { setActiveEmployees, setProjectsReducer } = projectsSlice.actions

export default projectsSlice.reducer

export const selectCurrentEmployees = state => state.projects.activeEmployees
export const selectCurrentProjectList = state => state.projects.projectsArray
export const selectIsFetching = state => state.projects.isFetching
export const selectTotalItemsCount = state => state.projects.totalItemsCount
export const selectEmployeesList = state => state.projects.activeEmployees
export const selectCurrentPage = state => state.projects.activeEmployees
