import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'
import authReducer from '../features/auth/authSlice'
import appReducer from '../features/app/appSlice'
import { setupListeners } from '@reduxjs/toolkit/query'
import projectsSlice from '../features/projects/projectsSlice'
import invoicesSlice from '../features/invoices/invoicesSlice'
import usersSlice from '../features/users/usersSlice'
import companyDataSlice from '../features/companyData/companyDataSlice'
import parcelsSlice from '../features/parcels/parcelsSlice'
import departmentsSlice from '../features/departments/departmentsSlice'
import categoriesSlice from '../features/delegationCategories/categoriesSlice'
import fileCategoriesSlice from '../features/delegationFileCategories/fileCategoriesSlice'
import delegationSlice from '../features/delegationProcess/delegationSlice'
import processingParcelsSlice from '../features/processingParcels/processingParcelsSlice'

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		auth: authReducer,
		app: appReducer,
		projects: projectsSlice,
		invoices: invoicesSlice,
		users: usersSlice,
		companyData: companyDataSlice,
		parcels: parcelsSlice,
		departments: departmentsSlice,
		delegationCategories: categoriesSlice,
		delegationFileCategories: fileCategoriesSlice,
		delegation: delegationSlice,
		processingParcels: processingParcelsSlice
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(apiSlice.middleware),
	devTools: true
})

setupListeners(store.dispatch)
