import React from 'react'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Profile from './Profile'

export function withRouter(Children) {
	return props => {
		const match = { params: useParams() }
		return <Children {...props} match={match} />
	}
}

class ProfileContainer extends React.Component {
	render() {
		return <Profile {...this.props} userProfile={this.props.authUserData} />
	}
}

let mapStateToProps = state => ({
	authUserData: state.auth.user
})

export default compose(
	connect(mapStateToProps, {}),
	withRouter
)(ProfileContainer)
