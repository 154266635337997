import { Layout, Spin } from 'antd'

import { Route, Routes, useLocation } from 'react-router-dom'
import AppLayout from '../AppLayout'
import Welcome from '../../features/auth/Welcome'
import RequireAuth from '../../features/auth/RequireAuth'

import ProfileContainer from '../Profile/ProfileContainer'
import { Content } from 'antd/es/layout/layout'
import SidebarContent from '../Sidebar/SidebarContent'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectCurrentState,
	setDarkSidebar,
	setDeviceViewPort
} from '../../features/app/appSlice'
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import i18next from 'i18next'
import {
	selectUsersGlobalWarningMessage,
	selectUsersPermissions
} from '../../features/auth/authSlice'

const MainPage = lazy(() => import('../MainPage/MainPage'))
const Login = lazy(() => import('../LoginForm/Login'))
const ProjectsContent = lazy(() => import('../Projects/ProjectsContent'))
const InvoiceContent = lazy(() => import('../Projects/Invoice/InvoiceContent'))
const ClientsContent = lazy(() => import('../Clients/clientsContent'))
const ParcelsGroupContent = lazy(() => import('../Parcels/ParcelsGroupContent'))
const CompanyDataContent = lazy(() =>
	import('../CompanyData/CompanyDataContent')
)
const DepartmentsContent = lazy(() =>
	import('../Projects/Departments/DepartmentsContent')
)
const DelegationCategoriesContent = lazy(() =>
	import('../Projects/Categories/CategoriesContent')
)
const DelegationFileCategoriesContent = lazy(() =>
	import('../Projects/FileCategories/FileCategoriesContent')
)
const DelegationProcessContent = lazy(() =>
	import('../Projects/Delegation/DelegationContent')
)
const ProcessingParcels = lazy(() =>
	import('../Parcels/ProcessingParcels/ProcessingParcelsContent')
)
const ProviderParcels = lazy(() =>
	import('../Parcels/ProcessingParcels/ProviderParcels/ProviderParcelsContent')
)
const NonDashboardRoutes = [
	'/login',
	'/signup',
	'/forgot',
	'/lockscreen',
	'/_error'
]

const Page = () => {
	const [i18nLoaded, setI18nLoaded] = useState(false)
	const state = useSelector(selectCurrentState)
	const userPermissions = useSelector(selectUsersPermissions)
	const globalWarningMessage = useSelector(selectUsersGlobalWarningMessage)
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
	let location = useLocation()
	const isNotDashboard = NonDashboardRoutes.includes(location.pathname)

	const dispatch = useDispatch()

	const setDarkSidebarTheme = async () => {
		dispatch(setDarkSidebar())
	}

	const setDeviceViewPortAc = useCallback(() => {
		let deviceViewPort = ''
		if (viewportWidth < 480) {
			deviceViewPort = 'XS'
		} else if (viewportWidth >= 480 && viewportWidth < 576) {
			deviceViewPort = 'SM'
		} else if (viewportWidth >= 576 && viewportWidth < 768) {
			deviceViewPort = 'MD'
		} else if (viewportWidth >= 768 && viewportWidth < 992) {
			deviceViewPort = 'LG'
		} else if (viewportWidth >= 1200 && viewportWidth < 1600) {
			deviceViewPort = 'XL'
		} else if (viewportWidth >= 1200) {
			deviceViewPort = 'XXL'
		}
		dispatch(setDeviceViewPort({ deviceViewPort }))
	}, [dispatch, viewportWidth])

	useEffect(() => {
		const handleResize = () => {
			setViewportWidth(window.innerWidth)
		}

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [setDeviceViewPortAc])

	useEffect(() => {
		i18next.on('loaded', function (loaded) {
			setI18nLoaded(true)
		})
	}, [])

	useEffect(() => {
		setDeviceViewPortAc()
	}, [viewportWidth, setDeviceViewPortAc])

	const token = window.localStorage.getItem('token')

	return (
		<>
			<div
				className={'topAffix'}
				style={!isNotDashboard ? {} : { display: 'none' }}
			></div>
			<div className={'app'} style={!isNotDashboard ? {} : { padding: 0 }}>
				<Layout className='container'>
					{!isNotDashboard && i18nLoaded && token && (
						<SidebarContent
							setDarkSidebarTheme={setDarkSidebarTheme}
							sidebarTheme={state.darkSidebar ? 'dark' : 'light'}
							sidebarIcons={state.sidebarIcons}
							collapsed={state.collapsed}
							userPermissions={userPermissions}
							globalWarningMessage={globalWarningMessage}
						/>
					)}

					<Layout
						style={{
							marginLeft:
								isNotDashboard || !token
									? 0
									: viewportWidth <= 480
									? 0
									: state.collapsed
									? 90
									: 250,
							transition: 'all 0.2s'
						}}
					>
						{!isNotDashboard && i18nLoaded}
						<Content
							className={'mainContent'}
							style={!isNotDashboard ? {} : { padding: 0 }}
						>
							<Suspense
								fallback={
									<>
										<div className={'fullSpinner'}>
											<Spin size='large' spinning={true} className={'spinnn'} />
										</div>
									</>
								}
							>
								<Routes>
									<Route path='/' element={<AppLayout />}>
										{/* public routs */}
										<Route exact index element={<Login />} />
										<Route exact index path='login' element={<Login />} />

										{/* protected routs */}
										<Route element={<RequireAuth />}>
											<Route exact path='/MainPage' element={<MainPage />} />
											<Route path={'welcome'} element={<Welcome />} />
											<Route
												exact
												path='/ProfileContainer/'
												element={<ProfileContainer />}
											/>
											<Route
												exact
												path='/projects'
												element={<ProjectsContent />}
											/>
											<Route
												exact
												path='/Invoice/:projectId'
												element={<InvoiceContent />}
											/>
											<Route
												exact
												path='/delegation/:projectId'
												element={<DelegationProcessContent />}
											/>
											<Route
												exact
												path='/Clients'
												element={<ClientsContent />}
											/>
											<Route
												exact
												path='/CompanyData'
												element={<CompanyDataContent />}
											/>
											<Route
												exact
												path='/ParcelGroup'
												element={<ParcelsGroupContent />}
											/>
											<Route
												exact
												path='/delegationDepartments'
												element={<DepartmentsContent />}
											/>
											<Route
												exact
												path='/delegationCategory'
												element={<DelegationCategoriesContent />}
											/>
											<Route
												exact
												path='/delegationFileCategory'
												element={<DelegationFileCategoriesContent />}
											/>
											<Route
												exact
												path='/processingParcelGroups'
												element={<ProcessingParcels />}
											/>
											<Route
												exact
												path='/providerParcels'
												element={<ProviderParcels />}
											/>
										</Route>
									</Route>
								</Routes>
							</Suspense>
						</Content>
					</Layout>
				</Layout>
			</div>
		</>
	)
}

export default Page
