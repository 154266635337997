import { createSlice } from '@reduxjs/toolkit'

const parcelsSlice = createSlice({
	name: 'parcels',
	initialState: {
		parcelsDepartments: null,
		parcelsGroupsStatuses: null,
		parcelsGroupsList: null,
		parcelsGroupsFlights: null,
		parcelsList: null,
		parcelsRecordsNumber: 0,
		parcelsRoomUsers: null
	},
	reducers: {
		setDepartmentsData: (state, action) => {
			const { parcelsDepartments } = action.payload
			state.parcelsDepartments = parcelsDepartments
		},
		setParcelsGroupsStatuses: (state, action) => {
			const { parcelsGroupsStatuses } = action.payload
			state.parcelsGroupsStatuses = parcelsGroupsStatuses
		},
		setParcelsGroupsList: (state, action) => {
			const { parcelsGroupsListTranslated } = action.payload
			state.parcelsGroupsList = parcelsGroupsListTranslated
		},
		setParcelsGroupsFlights: (state, action) => {
			const { parcelsGroupsFlights } = action.payload
			state.parcelsGroupsFlights = parcelsGroupsFlights
		},
		setParcelsList: (state, action) => {
			const { parcelsModifiedList } = action.payload
			state.parcelsList = parcelsModifiedList
		},
		setParcelsRecordsNumber: (state, action) => {
			const { parcelsRecordsNumber } = action.payload
			state.parcelsRecordsNumber = parcelsRecordsNumber
		},
		setParcelsRoomsUsers: (state, action) => {
			const { parcelsRoomUsers } = action.payload
			state.parcelsRoomUsers = parcelsRoomUsers
		}
	}
})

export const {
	setDepartmentsData,
	setParcelsGroupsList,
	setParcelsList,
	setParcelsGroupsStatuses,
	setParcelsGroupsFlights,
	setParcelsRecordsNumber,
	setParcelsRoomsUsers
} = parcelsSlice.actions

export default parcelsSlice.reducer

export const selectParcelsDepartmentsData = state =>
	state.parcels.parcelsDepartments
export const selectParcelsGroupsStatusesData = state =>
	state.parcels.parcelsGroupsStatuses
export const selectParcelsGroupsFlightsData = state =>
	state.parcels.parcelsGroupsFlights
export const selectParcelsGroups = state => state.parcels.parcelsGroupsList
export const selectParcels = state => state.parcels.parcelsList
export const selectParcelsRecordsNumber = state =>
	state.parcels.parcelsRecordsNumber
export const selectParcelsRoomUsers = state => state.parcels.parcelsRoomUsers
