import { createSlice } from '@reduxjs/toolkit'

const usersSlice = createSlice({
	name: 'users',
	initialState: {
		usersArray: null,
		pageSize: 10,
		totalItemsCount: 10,
		currentPage: 1
	},
	reducers: {
		setUsersReducer: (state, action) => {
			const { usersList } = action.payload
			state.usersArray = usersList
		}
	}
})

export const { setUsersReducer } = usersSlice.actions
export default usersSlice.reducer

export const selectCurrentUsersList = state => state.users.usersArray
