import React from 'react'
import AppProvider from './Components/shared/AppProvider'
import { ConfigProvider } from 'antd'

function App() {
	return (
		// <ConfigProvider theme={{
		//     token: {
		//         colorPrimary: "green"
		//     }
		// }}>
		//     <AppProvider />
		// </ConfigProvider>
		<AppProvider />
	)
}

export default App
